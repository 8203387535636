import DateRangeToken from '@apps/dashboards/admin/tokens/daterange'
import Container from '@admin/components/container'
import Chart from '@admin/components/chart'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'
import qs from 'qs'

class Card extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    daterange: PropTypes.object,
    data: PropTypes.array,
    social_campaign: PropTypes.object,
    isExpanded: PropTypes.bool,
    program: PropTypes.object,
    showChart: PropTypes.bool,
    showTable: PropTypes.bool
  }

  render() {
    const { daterange, isExpanded, showChart } = this.props
    const keys = this._getKeys()
    const data = this._getData(keys)
    const metric = this._getMetric(data)
    return (
      <div className="maha-dashboard-card-detail">
        <div className="maha-dashboard-card-detail-header">
          <div className="maha-dashboard-card-detail-title">
            <T text={ this._getLabel(keys.title) } />
          </div>
          <div className="maha-dashboard-card-detail-subtitle">
            <DateRangeToken daterange={ daterange } />
          </div>
          <div className="maha-dashboard-card-detail-metric">
            <div className="maha-dashboard-card-detail-metric-inner">
              <div className="maha-dashboard-card-detail-metric-text">
                { metric.value }
              </div>
              { metric.delta &&
                <div className={`maha-dashboard-card-detail-metric-delta ${ metric.delta.direction }`}>
                  <Icon icon={`caret-${ metric.delta.direction }`} /> { metric.delta.percent }
                </div>
              }
            </div>
          </div>
        </div>
        { showChart &&
          <div className="maha-dashboard-card-detail-chart">
            <Chart { ...this._getChart(keys, data) } />
          </div>
        }
        { isExpanded &&
          <div className="maha-dashboard-card-detail-table">
            <table>
              <thead>
                <tr>
                  <th><T text="t(Date)" /></th>
                  <th><T text={ keys.title } /></th>
                </tr>
              </thead>
              <tbody>
                { data.map((record, index) => (
                  <tr key={`record_${index}`} onClick={ this._handleClick.bind(this, keys.metric, record) }>
                    <td>{ this._getRange(record) }</td>
                    <td>
                      <div className="maha-dashboard-card-detail-amount">{ record.all.current }</div>
                      { record.all.delta &&
                        <div className={`maha-dashboard-card-detail-delta ${record.all.delta.direction}`}>
                          <Icon icon={`caret-${ record.all.delta.direction }`} />
                          { numeral(record.all.delta.percent).format('0.00%') }
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }

  _getChart(keys, data) {
    const { config, isExpanded } = this.props
    return {
      type: config.type,
      datasets: [
        {
          color: config.color,
          label: keys.title,
          points: data.map(record => ({
            x: this._getX(record),
            y: record.all.current
          }))
        },
        // {
        //   color: '#1877F2',
        //   label: 'Facebook',
        //   points: data.map(record => ({
        //     x: this._getX(record),
        //     y: record.facebook.current
        //   }))
        // },
        // {
        //   color: '#E4405F',
        //   label: 'Instagram',
        //   points: data.map(record => ({
        //     x: this._getX(record),
        //     y: record.instagram.current
        //   }))
        // }
      ],
      options: {
        aspectRatio: isExpanded ? 2 : 1.5,
        points: config.points,
        tension: config.tension,
        grid: isExpanded,
        stacked: true
      }
    }
  }

  _getData(keys) {
    const { data } = this.props
    return data.map(record => ({
      start: record.start,
      end: record.end,
      all: record[`${keys.metric}`].all,
      facebook: record[`${keys.metric}`].facebook,
      instagram: record[`${keys.metric}`].instagram
    }))
  }

  _getKeys() {
    const { metric } = this.props.config
    return {
      metric,
      ...metric === 'comments' ? {
        title: 't(Comments)'
      } : metric === 'clicks' ? {
        title: 't(Clicks)'
      } : metric === 'engagement' ? {
        title: 't(Engagement)'
      } : metric === 'impressions' ? {
        title: 't(Impressions)'
      } : metric === 'reach' ? {
        title: 't(Reach)'
      } : metric === 'shares' ? {
        title: 't(Shares)'
      } : metric === 'saves' ? {
        title: 't(Saves)'
      } : metric === 'video_views' ? {
        title: 't(Video Views)'
      } : metric === 'reactions' ? {
        title: 't(Reactions)'
      } : {}
    }
  }

  _getLabel(label) {
    const { config, social_campaign, program } = this.props
    const { team } = this.context.admin
    if(config.scope === 'program' && team.has_programs) return `${label} - ${program.title}`
    if(config.scope === 'social_campaign') return `${label} - ${social_campaign.title}`
    return label
  }

  _getMetric(data) {
    const previous_data = data.filter(record => record.all.previous > 0)
    const current_data = data.filter(record => record.all.current > 0)
    const previous = previous_data.reduce((total, record) => total + record.all.previous, 0)
    const current = current_data.reduce((total, record) => total + record.all.current, 0)
    const amount = current - previous
    const percent = amount === 0 ? null : previous === 0 ? 1 : Math.abs((current - previous) / previous)
    return {
      value: numeral(current).format('0,0'),
      delta: percent > 0 ? {
        direction: amount < 0 ? 'down' : 'up',
        percent: percent && percent !== 0 ? numeral(percent).format('0.00%') : null
      } : null
    }
  }

  _getRange(record) {
    const { step } = this.props.daterange
    const { end, start } = record
    if(step === 'day') return moment(start).format('MMM D, YYYY')
    if(step === 'week') return `${ moment(start).format('MMM D') } - ${ moment(end).format('MMM D, YYYY') }`
    if(step === 'month') return moment(start).format('MMM YYYY')
    if(step === 'quarter') return `${ moment(start).format('[Q]Q YYYY') } (${ moment(start).format('MMM D') } - ${ moment(end).format('MMM D') })`
    if(step === 'year') return moment(start).format('YYYY')
  }

  _getX(record) {
    const { step } = this.props.daterange
    const { end, start } = record
    if(step === 'day') return moment(start).format('M/D/YY')
    if(step === 'week') return `${ moment(start).format('M/D/YY') } - ${ moment(end).format('M/D/YY') }`
    if(step === 'month') return moment(start).format('MMM YYYY')
    if(step === 'quarter') return moment(start).format('[Q]Q YYYY')
    if(step === 'year') return moment(start).format('YYYY')
  }

  _handleClick(metric, record) {
    const { social_campaign, program } = this.props
    const filter = qs.stringify({
      metric,
      program_id: program.id,
      ...social_campaign ? { social_campaign_id: social_campaign.id } : {},
      start_date: moment(record.start).format('YYYY-MM-DD'),
      end_date: moment(record.end).format('YYYY-MM-DD')
    }, { encode: false })
    this.context.router.push(`/admin/dashboards/campaigns/social_campaign_metric?${filter}`)
  }

}

const mapResources = (props, context) => ({
  program: `/api/admin/team/programs/${props.config.program_id}`,
  ...props.config.scope === 'social_campaign' ? {
    social_campaign: `/api/admin/campaigns/social/${props.config.social_campaign_id}`
  } : {},
  data: {
    endpoint: '/api/admin/dashboards/campaigns/social_campaign_metric/card',
    filter: {
      social_campaign_id: props.config.social_campaign_id,
      program_id: props.config.program_id,
      daterange: props.daterange 
    }
  }
})

export default Container(mapResources)(Card)
