import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `campaigns_social_campaign_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const SocialCampaignVariantToken = ({ social_campaign_variant }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(social_campaign_variant) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { social_campaign_variant.social_campaign.type.toUpperCase() }: { social_campaign_variant.social_campaign.title }<br />
      </div>
      <div className="token-stats">
        { social_campaign_variant.profile.service.name.toUpperCase() }
      </div>
      <div className="token-stats">
        <span className={ social_campaign_variant.social_campaign.status }>{ social_campaign_variant.social_campaign.status }</span>
        <span><Date datetime={ social_campaign_variant.social_campaign.posted_at } /></span>
      </div>
    </div>
  </div>
)

SocialCampaignVariantToken.propTypes = {
  social_campaign_variant: PropTypes.object
}

export default SocialCampaignVariantToken
