import Stack from '@admin/components/stack'
import matchPath from './matchPath'
import PropTypes from 'prop-types'
import React from 'react'

export const RouterContext = React.createContext()
RouterContext.displayName = 'RouterContext'

export const useRouterContext = () => React.useContext(RouterContext)

class RouterStack extends React.Component {

  static childContextTypes = {
    stack: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    maha: PropTypes.object
  }

  static propTypes = {
    router: PropTypes.object,
    action: PropTypes.string,
    pathname: PropTypes.string,
    query: PropTypes.object
  }

  stackRef = React.createRef()

  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return (
      <RouterContext.Provider value={ this.getChildContext() }>
        <Stack { ...this._getStack() } />
      </RouterContext.Provider>
    )
  }

  componentDidMount() {
    const { team } = this.context.admin 
    const { pathname, query } = this.props
    if(pathname === `/${team.subdomain}`) return
    const card = this._matchRoute(pathname, query)
    if(!card) return
    this._handlePush(card)
  }

  componentDidUpdate(prevProps) {
    const { action, pathname, query } = this.props
    if(prevProps.pathname !== pathname) {
      if(action === 'PUSH') {
        if(/^\/[^/]*$/.test(pathname)) return
        const card = this._matchRoute(pathname, query)
        this._handlePush(card)
      } else if(action === 'POP') {
        setTimeout(this._handlePop, 50)
      }
    }
  }

  getChildContext() {
    return {
      stack: {
        push: this._handlePush,
        pop: this._handlePop
      }
    }
  }

  _getStack() {
    const { history } = this.props.router
    return {
      display_name: 'router',
      ref: this.stackRef,
      slideFirst: history.length === 1
    }
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(card) {
    this.stackRef.current.push(card)
  }

  _matchRoute(pathname, query) {
    const { routes } = this.context.maha
    if(pathname === '/') return null
    return Object.keys(routes).reduce((component, path) => {
      if(component) return component
      const matched = matchPath(pathname, { path, exact: true })
      if(!matched) return null
      return {
        pathname,
        component: routes[path],
        props: {
          pathname,
          params: matched ? matched.params : {},
          query
        }
      }
    }, null)
  }

}

export default RouterStack
